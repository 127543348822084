import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useHttp } from '../../hooks/http';

const Bopm: React.FC = () => {
  const history = useHistory();
  const { httpGet } = useHttp();
  const { user } = useAuth();

  useEffect(() => {
    async function load(): Promise<any> {
      const { status } = await httpGet('/bopms/pesquise');

      if (status === 200) {
        history.push('/RO/step');
      } else {
        history.push('/RO/occurrence/storage');
      }
    }
    load();
  }, [history, httpGet, user]);

  return (<></>);
};

export default Bopm;
