import { useAuth } from 'hooks/auth';
import { useHttp } from 'hooks/http';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';

/** componentes */
import { isBefore } from 'date-fns';
import Layout from '../../components/Layout';
import { DashIcon } from '../../styles/icons';
import Allocation from './Allocation';
import Favorites from './Favorites';

import { Container } from './styles';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { httpGet } = useHttp();

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const { data } = await httpGet('/me');
      const limit = await httpGet('/limit');
      const slug = data.roles.map((t: any) => (t.slug));
      const authorizedProfiles = ['coord', 'operational', 'auxiliar', 'fiscal', 'auxiliar_operacional'];
      const isProfileAuthorized = (slugList: any) => authorizedProfiles.some((profile) => slugList.includes(profile));

      // Testa os perfis autorizados a alterar o perfil
      if (isProfileAuthorized(slug)) {
        // Verifica se os dados necessitam ser autorizados
        if (!data?.function || !data?.rg || !data?.war_name || !data?.profile_updated_at
        || isBefore(new Date(data?.profile_updated_at), new Date(limit.data.profile_update_limit))) {
          history.push('/profile');
        }
      }
    }
    loadUser();
  }, []);// eslint-disable-line

  return (
    <Layout breadcrumbs={{ icon: DashIcon }}>
      {!user?.allocation && <Allocation />}
      {user?.allocation && (
        <Container>
          <Favorites />
        </Container>
      )}
    </Layout>
  );
};

export default Dashboard;
