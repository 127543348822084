import React, {
  createContext, useContext, useCallback, useState,
} from 'react';
import session from '../utils/session';

import api from '../services/api';

interface IAlocation {
  ended_at: Date;
  management_id: number;
  started_at: Date;
}

interface IAction {
  id: string;
  name: string;
  type: string;
}

interface IUserAuth {
  id?: number;
  name: string;
  email: string;
  document: string;
  phone: string;
  roles: string[];
  permissions: string[];
  allocation: IAlocation | null;
  is_intelligence: boolean;
  action: IAction | null;
}

interface SignInCredentials {
  document: string;
  password: string;
}

interface AuthContextData {
  user?: IUserAuth;
  token?: string;
  refresh_token?: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  iCan(can: string): Boolean;
  iIs(can: string): Boolean;
  signOut(): void;
  refreshUser(): void;
  refreshToken(): void;
}

interface AuthState {
  token?: string;
  refresh_token?: string;
  user?: IUserAuth;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = session.get('token');
    const refresh_token = session.get('refresh_token');
    const user = session.get('user');

    if (token && refresh_token && user) {
      return { token, refresh_token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ document, password }) => {
    const current_spa_version = process.env.REACT_APP_VERSION;
    const { status, data } = await api.post('/auth/login', {
      document,
      password,
    });

    if (status === 200) {
      const {
        token, refresh_token, user, spa_version,
      } = data;

      session.set('token', token);
      session.set('refresh_token', refresh_token);
      session.setObject('user', user);
      session.set('spa_version', spa_version);

      setData({ token, refresh_token, user });

      if (current_spa_version !== spa_version) {
        window.location.reload();
      }
    }
  }, []);

  const iIs = useCallback((is) => {
    if (!is) return true;
    const { user } = data;

    if (!user?.roles) {
      return false;
    }

    return user?.roles.some((role) => (role === is || role === 'admin')) || false;
  }, [data]);

  const iCan = useCallback((can) => {
    if (!can) return true;
    const { user } = data;

    if (!user?.permissions) {
      return false;
    }

    return user?.permissions.some((permission) => (permission === can || iIs('admin') === true)) || false;
  }, [data, iIs]);

  const signOut = useCallback(() => {
    const refresh_token = session.get('refresh_token');

    localStorage.clear();
    api.post('/auth/logout', { refresh_token });
    setData({} as AuthState);
  }, []);

  const refreshToken = useCallback(async () => {
    const refresh_token = session.get('refresh_token');

    const response = await api.post('/auth/refresh', { refresh_token });
    if (response.status === 200) {
      session.set('token', response.data.token);
      session.set('refresh_token', response.data.refreshToken);
      session.set('spa_version', response.data.spa_version);
      setData({
        ...data,
        token: response.data.token,
        refresh_token: response.data.refreshToken,
      });
    }
  }, [data]);

  const refreshUser = useCallback(async () => {
    const token = session.get('token');
    const refresh_token = session.get('refresh_token');
    const user = session.get('user');

    if (token && refresh_token && user) {
      setData({ token, refresh_token, user: JSON.parse(user) });
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      user: data.user,
      token: data.token,
      refresh_token: data.refresh_token,
      signIn,
      iCan,
      iIs,
      signOut,
      refreshToken,
      refreshUser,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AutProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
