const typeWarrant = [
  { value: 'BUSCA E APREENSÃO', label: 'BUSCA E APREENSÃO' },
  { value: 'BUSCA E APREENSÃO MENOR INFRATOR', label: 'BUSCA E APREENSÃO MENOR INFRATOR' },
  { value: 'ESTUPRO', label: 'ESTUPRO' },
  { value: 'FURTO', label: 'FURTO' },
  { value: 'FURTO A TRANSEUNTE', label: 'FURTO A TRANSEUNTE' },
  { value: 'FURTO A APARELHO CELULAR', label: 'FURTO A APARELHO CELULAR' },
  { value: 'FURTO A ESTABELECIMENTO COMERCIAL', label: 'FURTO A ESTABELECIMENTO COMERCIAL' },
  { value: 'FURTO A VEÍCULO', label: 'FURTO A VEÍCULO' },
  { value: 'FURTO A BICICLETA', label: 'FURTO A BICICLETA' },
  { value: 'HOMICÍDIO', label: 'HOMICÍDIO' },
  { value: 'MARIA DA PENHA', label: 'MARIA DA PENHA' },
  { value: 'TRÁFICO', label: 'TRÁFICO' },
  { value: 'PENSÃO ALIMENTÍCIA', label: 'PENSÃO ALIMENTÍCIA' },
  { value: 'PREVENTIVO', label: 'PREVENTIVO' },
  { value: 'ROUBO', label: 'ROUBO' },
  { value: 'ROUBO A TRANSEUNTE', label: 'ROUBO A TRANSEUNTE' },
  { value: 'ROUBO A APARELHO CELULAR', label: 'ROUBO A APARELHO CELULAR' },
  { value: 'ROUBO A ESTABELECIMENTO COMERCIAL', label: 'ROUBO A ESTABELECIMENTO COMERCIAL' },
  { value: 'ROUBO DE VEÍCULO', label: 'ROUBO DE VEÍCULO' },
  { value: 'ROUBO NO INTERIOR DE ESTABELECIMENTO', label: 'ROUBO NO INTERIOR DE ESTABELECIMENTO' },
  { value: 'OUTROS', label: 'OUTROS' },

];

export default typeWarrant;
