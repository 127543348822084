import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useToast } from 'hooks/toast';
import * as Yup from 'yup';
import guns from 'utils/guns';
import caliber from 'utils/caliber';
import brandGuns from 'utils/brandGuns';
import colors from 'utils/colors';
import brandAmmunition from 'utils/brandAmmunition';
import brandCars from 'utils/brandCars';
import { Checkbox } from '@material-ui/core';
import typeNarcotics from 'utils/typeNarcotics';
import Simple from 'components/Headers/Simple';
import Layout from '../../../../components/Layout';
import {
  Container, GridButtons, WrapperBtnCancel, WrapperBtnSubmit, WrapperAddress, Title,
} from './styles';
import {
  BoxOpen, Bullets, CarIcon, Medicines, Pistol, UserFriends,
} from '../../../../styles/icons';
import {
  WrapperLabel, Select, Button, Input, InputMask, InputHidden,
} from '../../../../components/Forms';
import { useHttp } from '../../../../hooks/http';
import getValidationError from '../../../../utils/getValidationsErros';
import measurement from 'utils/measurement';

const StoreSeizure: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { httpGet, httpPost } = useHttp();
  const history = useHistory();
  const { addToast } = useToast();
  const ROId = localStorage.getItem('ROId');
  const { type } = useParams<{ type: string }>();
  const [involved, setInvolved] = useState([]);
  const [police, setPoliceCivil] = useState([]);
  const [check, setCheck] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    async function loadInvolved(): Promise<any> {
      const { status, data } = await httpGet(`/involveds/list/${ROId}`);
      if (status === 200) {
        const involvedParsed = data
          .map((involved: any) => ({
            value: involved.id,
            label: involved.name,
          }));
        involvedParsed.unshift({ value: '', label: 'Não houve envolvido' });
        setInvolved(involvedParsed);
      }
    }

    loadInvolved();
  }, [ROId, httpGet]);

  useEffect(() => {
    async function loadPoliceCivil(): Promise<any> {
      const { status, data } = await httpGet(`/civilPolices/list/${ROId}`);
      if (status === 200) {
        const policeCivilParsed = data
          .map((police: any) => ({
            value: police.id,
            label: `${police.name} / ${police.document}`,
          }));
        policeCivilParsed.unshift({ value: '', label: 'Não houve recebedor' });
        setPoliceCivil(policeCivilParsed);
      }
    }

    loadPoliceCivil();
  }, [ROId, httpGet]);

  const handleChangeShaved = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableInput(event.target.checked);
    setCheck(event.target.checked);
    formRef.current?.setFieldValue('serial', '');
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const _seizure = {
      ...data,
      bopm_id: ROId,
      civil_police_id: data.civil_police_id?.value || '',
      involved_id: data.involved_id?.value || '',
      brand: data.brand?.value || data.brand || '',
      model: data.model?.value || data.model || '',
      color: data.color?.value || data.color || '',
      type: data.type?.value || data.type || '',
      caliber: data.caliber?.value || data.caliber || '',
      year: data.year || '',
      amount: data.amount || '',
      measure: data.measure?.value || '',
      serial: data.serial || '',
      plate: data.plate || '',
      destiny: data.destiny || '',
      shaved: data.shaved || '',
      seizure_type: data.seizure_type || '',
    };

    try {
         formRef.current?.setErrors({});
         const schema = Yup.object().shape({

           seizure_type: Yup.string().required(),

           brand: Yup.string().when('seizure_type', {
             is: (val) => val === 'Entorpecentes',
             then: Yup.string().notRequired(),
             otherwise: Yup.string().required('Marca/Fabricante é obrigatório'),
           }),

           model: Yup.string().when('seizure_type', {
             is: (val) => val === 'Entorpecentes' || val === 'Munições',
             then: Yup.string().notRequired(),
             otherwise: Yup.string().required('Modelo é obrigatório'),
           }),

           color: Yup.string().when('seizure_type', {
             is: (val) => val === 'Veículos',
             then: Yup.string().required('Cor é obrigatório'),
             otherwise: Yup.string().notRequired(),
           }),

           type: Yup.string().when('seizure_type', {
             is: (val) => val === 'Veículos' || val === 'Munições',
             then: Yup.string().notRequired(),
             otherwise: Yup.string().required('Tipo é obrigatório'),
           }),

           caliber: Yup.string().when('seizure_type', {
             is: (val) => val === 'Armas' || val === 'Munições',
             then: Yup.string().required('Calibre é obrigatório'),
             otherwise: Yup.string().notRequired(),
           }),

           year: Yup.string().when('seizure_type', {
             is: (val) => val === 'Veículos',
             then: Yup.string().required('Ano é obrigatório'),
             otherwise: Yup.string().notRequired(),
           }),

           amount: Yup.string().when('seizure_type', {
             is: (val) => val === 'Veículos' || val === 'Armas',
             then: Yup.string().notRequired(),
             otherwise: Yup.string().required('Quantidade é obrigatório'),
           }),

           measure: Yup.string().when('seizure_type', {
            is: (val) => val === 'Entorpecentes',
            then: Yup.string().required('Medida é obrigatório'),
            otherwise: Yup.string().notRequired(),
           }),

           shaved: Yup.string(),

           serial: Yup.string().when('seizure_type', {
             is: (val) => val === 'Entorpecentes' || val === 'Munições' || val === 'Veículos',
             then: Yup.string().notRequired(),
             otherwise: Yup.string().when('shaved', {
               is: (val) => val === 'false',
               then: Yup.string().min(5).required('Número de serie obrigatório'),
               otherwise: Yup.string().notRequired(),
             }),
           }),

           plate: Yup.string().when('seizure_type', {
             is: (val) => val === 'Veículos',
             then: Yup.string().when('serial', {
               is: (val) => val === '',
               then: Yup.string().min(7, 'Informe uma Placa válida'),
               otherwise: Yup.string().notRequired(),
             }),
             otherwise: Yup.string().notRequired(),
           }),

           involved_id: Yup.string().typeError('Informar se houve realacionamento com o envolvido.'),

         });

         await schema.validate(_seizure, { abortEarly: false });
         const { status } = await httpPost('seizures', _seizure);

         if (status === 200) {
           addToast({ title: 'Salvo com sucesso!', type: 'success' });
           history.push('/RO/seizures/show');
         } else {
           addToast({ title: 'Não foi possível gravar!', type: 'error' });
         }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, ROId, history, httpPost]);

  const breadcrumbs = {
    icon: UserFriends,
    links: [{ path: `/RO/${ROId}`, title: 'RO-SP / Apreenções / Cadastrar' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title>
            {type === 'cars' && (<><CarIcon /> Cadastrar Veículo </>)}
            {type === 'narcotics' && (<><Medicines /> Cadastrar Entorpecentes </>)}
            {type === 'ammunitions' && (<><Bullets /> Cadastrar Munições</>)}
            {type === 'guns' && (<><Pistol /> Cadastrar Arma</>)}
            {type === 'material' && (<> <BoxOpen /> Cadastrar Material</>)}
          </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                {type === 'cars' && (
                <>
                  <InputHidden name="seizure_type" defaultValue="Veículos" />

                  <WrapperLabel label="Placa" xs={12} sm={6} md={6}>
                    <InputMask
                      name="plate"
                      mask="aaa-9*99"
                      style={{ textTransform: 'uppercase' }}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Marca" xs={12} sm={6} md={6}>
                    <Select
                      name="brand"
                      options={brandCars}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Modelo" xs={12} sm={6} md={6}>
                    <Input name="model" placeholder="" />
                  </WrapperLabel>

                  <WrapperLabel label="Cor" xs={12} md={6} sm={6}>
                    <Select
                      name="color"
                      options={colors}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Ano exercício" xs={12} sm={6} md={6}>
                    <InputMask
                      name="year"
                      mask="9999"
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Chassi (Mín 5 Caracter)" xs={12} sm={6} md={6}>
                    <Input name="serial" placeholder="" />
                  </WrapperLabel>

                </>
                )}

                {type === 'narcotics' && (
                <>
                  <InputHidden name="seizure_type" defaultValue="Entorpecentes" />

                  <WrapperLabel label="Entorpecente" xs={12} sm={6} md={6}>
                    <Select
                      name="type"
                      options={typeNarcotics}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Quantidade" xs={12} md={6} sm={6}>
                    <Input name="amount" placeholder="" />
                  </WrapperLabel>
                  
                  <WrapperLabel label="Medida" xs={12} sm={6} md={6}>
                    <Select
                    name="measure"
                    options={measurement}
                  />
                  </WrapperLabel>

                  <WrapperLabel label="Destino" xs={12} md={6} sm={6}>
                    <Input name="destiny" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>
                </>
                )}

                {type === 'ammunitions' && (
                <>
                  <InputHidden name="seizure_type" defaultValue="Munições" />

                  <WrapperLabel label="Quantidade" xs={12} sm={3} md={3}>
                    <Input name="amount" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Calibre" xs={12} md={3} sm={3}>
                    <Select
                      name="caliber"
                      options={caliber}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Fabricante" xs={12} sm={6} md={6}>
                    <Select
                      name="brand"
                      options={brandAmmunition}
                    />
                  </WrapperLabel>

                </>
                )}

                {type === 'guns' && (
                <>
                  <InputHidden name="seizure_type" defaultValue="Armas" />

                  <WrapperLabel label="Marca" xs={12} sm={4} md={4}>
                    <Select
                      name="brand"
                      options={brandGuns}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Modelo" xs={12} sm={4} md={4}>
                    <Input name="model" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Calibre" xs={12} md={4} sm={4}>
                    <Select
                      name="caliber"
                      options={caliber}
                    />
                  </WrapperLabel>

                  <WrapperLabel label="Tipo" xs={12} md={5} sm={5}>
                    <Select
                      name="type"
                      options={guns}
                    />
                  </WrapperLabel>

                  <InputHidden name="shaved" value={check.toString()} />

                  <WrapperLabel label="Número da arma" xs={9} sm={6} md={6}>
                    <Input name="serial" minLength={5} disabled={disableInput} style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Raspada." xs={3} sm={1} md={1}>
                    <Checkbox color="default" checked={check} onChange={handleChangeShaved} />
                  </WrapperLabel>
                </>

                )}

                {type === 'material' && (
                <>
                  <InputHidden name="seizure_type" defaultValue="Materiais" />

                  <WrapperLabel label="Quantidade" xs={12} md={4} sm={4}>
                    <Input name="amount" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Tipo" xs={12} md={4} sm={4}>
                    <Input name="type" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Lacre" xs={12} sm={4} md={4}>
                    <Input name="serial" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Marca" xs={12} sm={6} md={6}>
                    <Input name="brand" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>

                  <WrapperLabel label="Modelo" xs={12} sm={6} md={6}>
                    <Input name="model" placeholder="" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>
                </>
                )}

                <WrapperLabel label="Relacionado ao envolvido?" xs={12} md={6} sm={6}>
                  <Select
                    name="involved_id"
                    options={involved}
                  />
                </WrapperLabel>

                <WrapperLabel label="Quem recebeu? (Nome/Matrícula)" xs={12} md={6} sm={6}>
                  <Select
                    name="civil_police_id"
                    options={police}
                  />
                </WrapperLabel>

              </Grid>
              <GridButtons>
                <WrapperBtnCancel>
                  <Button
                    type="button"
                    onClick={() => { history.push('/RO/seizures/show'); }}
                  >
                    Cancelar
                  </Button>
                </WrapperBtnCancel>
                <WrapperBtnSubmit>
                  <Button
                    type="submit"
                  >
                    Salvar
                  </Button>
                </WrapperBtnSubmit>
              </GridButtons>
            </UForm>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default StoreSeizure;
