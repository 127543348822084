import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form as UForm } from '@unform/web';
import { FaCopy, FaFileAlt } from 'react-icons/fa';
import IPaginate from 'interfaces/paginate';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import typeWarrant from 'utils/typeWarrant';
import IOption from 'interfaces/option';
import typeRegistration from 'utils/typeRegistration';
import FilterDrawerReturn from 'components/Headers/FilterDrawerReturn';
import { IOccurrence } from 'interfaces';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** Componentes */
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import { ArrowBarRightIcon, TrashIcon } from '../../../styles/icons';
import {
  WrapperLabel, Button, ButtonIcon, Select, Input,
  InputMask,
} from '../../../components/Forms';

/** interfaces */
import {
  Container, ContainerList, WrapperName, DrawerContent,
} from './styles';
import Pagination from '../../../components/Pagination';
import Drawer from '../../../components/Drawer';
import { parseDate } from 'utils';
import stringOptions from 'utils/stringOptions';

interface IFilter {
  id: string;
  RONumber: string;
  startDate: Date;
  management_id: number;
  dp: string;
  typeWarrant:IOption;
  typeRegistration: IOption;
}

const ROs: React.FC = () => {
  const [ROs, setROs] = useState<IOccurrence[]>();
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const RefLabelToggle = useRef<HTMLLabelElement>(null);
  const [paginate, setPaginate] = React.useState<IPaginate>({ page: 1, lastPage: 1, perPage: 10 });
  const history = useHistory();
  const { httpGet, loading } = useHttp();

  const getROs = useCallback(async (filter: IFilter, paginate: IPaginate) => {
    const { status, data } = await httpGet('bopms/answered', {
      params: { ...filter, ...paginate },
    });

    if (status === 200) {
      setROs(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    getROs(
          { id: '' } as IFilter,
          { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getROs, httpGet]);

  const handlePaginate = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getROs(filter, payload);
  }, [filter, getROs]);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      const filter = {
        ...payload,
        id: null,
        page: 1,
        perPage: 10,
        numberRO: payload.RONumber ? payload.RONumber : null,
        RONumber: payload.RONumber ? payload.RONumber : null,
        teamService: payload.teamService ? payload.teamService : null,
        startDate: payload.startDate ? `${parseDate(payload.startDate)} 00:00:00.000 -0300` : null,
        typeWarrant: payload.typeWarrant ? payload?.typeWarrant.value : null,
        typeRegistration: payload.typeRegistration ? payload?.typeRegistration.value : null,
      };

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        startDate: Yup.string().ensure(),
        RONumber: Yup.number().when('numberRO',{
          is: (val) => val === null,
          then: Yup.number().notRequired().nullable(),
          otherwise: Yup.number().typeError('Insira Número').positive('Número positivo').integer('Número Inteiro'),
        }),
      });

      await schema.validate(filter, { abortEarly: false });
      setFilter(filter);

      const { status, data } = await httpGet('bopms/answered', { params: filter });

      if (status === 200) {
        setROs(data.data);
        setPaginate(data.pagination);

        if (data.data.length > 0) {
              RefLabelToggle?.current?.click();
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
          formRef.current?.setErrors(erros);
      }
    }
  }, [httpGet]);

  const handleGoToDetail = useCallback((ro: any) => {
    if (ro?.is_finished) {
      history.push(`/RO/detail/${ro.id}`);
    } else {
      history.push('/RO/step');
    }
  }, [history]);

  const breadcrumbs = {
    icon: FaCopy,
    links: [{ path: '/dashboard', title: `RO-SP / Ocorrências Registradas - ${paginate.total}` }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <FilterDrawerReturn handleReturn={() => history.push('/dashboard')} handleDrawer={() => history.push('')} />
        <Drawer>
          <DrawerContent>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Nº RO-SP" xs={12}>
                  <Input name="RONumber" />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1}>
                <WrapperLabel label="Data" xs={12}>
                  <InputMask
                    name="startDate" mask={'99/99/9999'}
                  />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1}>
                <WrapperLabel label="CPF" xs={12}>
                  <InputMask name="teamService" mask={'999.999.999-99'}/>
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1}>
                <WrapperLabel label="Tipo de Registro" xs={12}>
                  <Select
                    name="typeRegistration"
                    options={typeRegistration}
                  />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1}>
                <WrapperLabel label="Tipo de Mandado" xs={12}>
                  <Select
                    name="typeWarrant"
                    options={typeWarrant}
                  />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1} justify="space-between" alignItems="baseline">
                <Grid item xs={10} md={4}>
                  <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                    <ButtonIcon title="Fechar">
                      <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                        <ArrowBarRightIcon />
                      </label>
                    </ButtonIcon>
                    <ButtonIcon
                      title="Limpar"
                      onClick={() => {
                                formRef.current?.reset();
                      }}
                    >
                      <TrashIcon />
                    </ButtonIcon>
                  </span>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={loading}> Filtrar </Button>
                </Grid>
              </Grid>
            </UForm>
          </DrawerContent>
        </Drawer>

        <Grid container justify="flex-start" spacing={1}>

          { ROs && ROs.map((ro: any) => (

            <Grid item key={ro.id} xs={12} sm={6} md={6}>
              <ContainerList onClick={() => handleGoToDetail(ro)} isResult={!ro?.is_finished}>
                <WrapperName>
                  <FaFileAlt />&nbsp;{ro.type_of_registration === 'RI' ? 'RI':'RO'}-SP N°&nbsp;<p>{ro?.bopm_number ? `${ro?.bopm_number}.${ro.management_id}.${format(new Date(ro?.created_at), 'yyyy')}` : 'Aguardando encerramento...'}</p>
                </WrapperName>
                <Grid container spacing={1}>
                  <Flex title={ro.type_of_registration === 'RI' ? 'Registro Interno' : 'Registro DP'} xs={12} sm={6} md={4}>
                    {`${ro?.type_of_registration} ${ro.type_of_registration === 'RI'
                      ? [ro.id?.toUpperCase().split('-', 1)] : `${ro?.registration_number} - ${ro?.dp}`}`}
                  </Flex>
                  <Flex title="Tipo de Mandado" xs={12} sm={6} md={4}>
                    {ro.type_of_warrant}
                  </Flex>
                  <Flex title="Base" xs={12} sm={6} md={4}>
                    {ro.base}
                  </Flex>
                  <Flex title="Guarnição de Serviço" xs={12} sm={12} md={12}>
                  { ro.service_team
                     && stringOptions(ro.service_team).map((t: any) => (<>{t.label} / </>))}
                  </Flex>
                  <Flex title="" xs={12} sm={12} md={12} />
                </Grid>
              </ContainerList>
            </Grid>
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default ROs;
