/* eslint-disable react/no-array-index-key */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ModalFactory from 'react-modal-promise';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import {
  formatDate, parseDate, removeAccents, validateCpf,
} from '../../../../utils';
import getCurrentPosition from '../../../../utils/getCurrentPosition';
import getValidationError from '../../../../utils/getValidationsErros';

/** hooks */
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';

/** components */
import {
  Button, ButtonIcon, Input, InputMask, WrapperLabel,
} from '../../../../components/Forms';
import { ModalDialog } from '../../../../components/ModalDialog';
import Modal from '../../../../components/ModalSimple';
import { InfoIcon, WarningIcon } from '../../../../styles/icons';

import StyleCard from './StyleCard';
import {
  Card,
  Container,
  Death,
  Div,
  GridButtons,
  Red,
  RedStyle,
  WrapperBtnCancel,
  WrapperBtnInfo,
  WrapperBtnSubmit,
  WrapperHidden,
  WrapperModal,
} from './styles';

interface IForm {
  coord: {
    lat: number,
    lng: number
  }
}

const Data: React.FC<IForm> = ({ coord }) => {
  const history = useHistory();
  const {
    httpGet, httpPost, unLoading, loading,
  } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const { user } = useAuth();
  const [formData, setFormData] = useState<any>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showData, setShowData] = useState(false);
  const [persons, setPersons] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    unLoading();
  }, [unLoading, loading]);

  const handleGetPerson = useCallback(async () => {
    const document = formRef.current?.getFieldValue('document');
    const name = formRef.current?.getFieldValue('name');
    const birthday = formRef.current?.getFieldValue('birthday');
    const mather = formRef.current?.getFieldValue('mather');

    const person = document
      ? { document: document?.replace(/\D/g, '') }
      : {
        name: removeAccents(name?.trim()),
        mather: removeAccents(mather?.trim()),
        birthday: parseDate(birthday),
      };

    let additionSchema;
    let addition2Schema;
    let dataSchema;

    if (!person.document && !showData) {
      additionSchema = {
        document: Yup.string().required('CPF é obrigatório'),
      };
    }

    if (person.document) {
      additionSchema = {
        document: Yup.string()
          .test('is-valid', 'Insira um CPF válido', (value) => validateCpf(value, true)),
      };
    }

    let birthdaySchema;
    if (person.birthday) {
      birthdaySchema = {
        birthday: Yup.date().typeError('Insira uma data Válida'),
      };
    }

    if (!person.name && showData) {
      dataSchema = {
        name: Yup.string().required('Nome é obrigatório'),
      };
    }

    if (!person.birthday && showData) {
      additionSchema = {
        birthday: Yup.date().required().typeError('Insira o Nascimento'),
      };
    }

    if (!person.mather && showData) {
      addition2Schema = {
        mather: Yup.string().required('Insira a Mãe'),
      };
    }
    const schema = Yup.object().shape({
      ...additionSchema,
      ...addition2Schema,
      ...dataSchema,
      ...birthdaySchema,
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(person, { abortEarly: false });
      setBtnLoading(true);

      const response: any = await httpGet('/orders/autocomplete/person', { params: { ...person } });
      if (response.status === 200) {
        const { data } = response;
        setBtnLoading(false);

        if (Object.keys(data).length === 0) {
          await ModalDialog({
            title: 'AVISO!',
            content: 'Não foi localizado, verifique os dados e consulte novamente.',
            buttons: 'ok',
          });
          setBtnLoading(false);
        }

        if (Object.keys(data).length > 30) {
          addToast({
            type: 'warning',
            title: 'Opsss!',
            description: 'A consulta excedeu ao limite permitido. Favor refinar os critérios de pesquisa.',
          });
          setBtnLoading(false);
          return;
        }

        if (Object.keys(data).length === 1) {
          setShowForm(false);
          setFormData(data[0]);
          formRef.current?.setData({
            ...data[0],
            document: data[0].document.replace(/\D/g, ''),
            birthday: formatDate(data[0].birthday),
            confirmed: [{ value: null, label: '' }],
          });
          labelConfRef.current?.click();
        }

        if (Object.keys(data).length > 1) {
          setShowForm(false);
          setFormData(person);
          formRef.current?.setData({
            ...person,
            birthday: formatDate(person.birthday!),
            confirmed: [{ value: null, label: '' }],
          });
          labelConfRef.current?.click();
        }
        persons.length > 0 && setPersons(data);
      }

      if (response.response?.status === 400) {
        setBtnLoading(false);
      }
      setBtnLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
        setBtnLoading(false);
      }
    }

  }, [history, httpGet, showData]); // eslint-disable-line

  const handleSubmit = useCallback(async (data: any) => {
    const personSubmit = {
      ...data,
      ...coord,
      name: data.name.toUpperCase(),
      mather: data.mather.toUpperCase(),
      birthday: parseDate(data.birthday),
      management_id: user?.allocation?.management_id,
      document: data.document?.replace(/\D/g, ''),
      type: 'person',
    };

    setBtnLoading(true);
    const position = await getCurrentPosition();

    const response: any = await httpPost('orders', {
      ...personSubmit,
      current_lat: position.lat,
      current_lng: position.lng,
    });
    if (response.response?.status === 302) {
      const { data } = response.response;
      history.push(`/order/detail/${data.id}`);
    }

    if (response.status === 200) {
      history.push('/orders/latest');
    }

    if (response.status !== 200) {
      setBtnLoading(false);
    }

    setBtnLoading(false);

  }, [coord, history, httpPost, user]); // eslint-disable-line

  const handleGoToDetail = useCallback(async (data: any) => {
    setShowForm(false);
    setFormData(data);
    formRef.current?.setData({
      ...data,
      document: data.document.replace(/\D/g, ''),
      birthday: formatDate(data.birthday),
      confirmed: [{ value: null, label: '' }],
    });
    labelConfRef.current?.click();
  }, []);

  const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowData(event.target.checked);
  }, []);

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleHover = useCallback(() => {
    setDisableBtn(false);
    labelConfRef.current?.click();
    formRef.current?.reset();
  }, []);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Div off={showData}>
          <Grid container spacing={1}>
            {!showData && (
              <>
                <WrapperLabel label="CPF" xs={12}>
                  <InputMask
                    name="document"
                    type="tel"
                    mask="999.999.999-99"
                  />
                </WrapperLabel>
                <WrapperHidden show={showForm}>
                  <WrapperLabel label="Nome Completo" xs={12}>
                    <Input name="name" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>
                  <WrapperLabel label="Nascimento" xs={12} sm={6}>
                    <InputMask name="birthday" mask="99/99/9999" placeholder="DD/MM/YYYY" disabled={!!formData?.birthday} />
                  </WrapperLabel>
                  <WrapperLabel label="Mãe" xs={12} sm={6}>
                    <Input name="mather" style={{ textTransform: 'uppercase' }} disabled={!!formData?.mather} />
                  </WrapperLabel>
                  <WrapperLabel label="RG" xs={12} sm={6}>
                    <Input name="document_secondary" type="tel" disabled={!!formData?.document_secondary} />
                  </WrapperLabel>
                  <WrapperLabel label="Pai" xs={12} sm={6}>
                    <Input name="father" style={{ textTransform: 'uppercase' }} disabled={!!formData?.father} />
                  </WrapperLabel>
                  <WrapperLabel label="Bnmp" xs={12} sm={6}>
                    <Input name="bnmp" style={{ textTransform: 'uppercase' }} disabled={!!formData?.bnmp} />
                  </WrapperLabel>
                </WrapperHidden>
              </>
            )}
            {showData && (
              <>
                <WrapperHidden show={showForm}>
                  <WrapperLabel label="CPF" xs={12}>
                    <InputMask
                      name="document"
                      type="tel"
                      mask="999.999.999-99"
                      disabled={!!formData?.document}
                    />formData
                  </WrapperLabel>
                </WrapperHidden>
                <WrapperLabel label="Nome Completo" xs={12}>
                  <Input name="name" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>
                <WrapperLabel label="Nascimento" xs={12} sm={6}>
                  <InputMask name="birthday" mask="99/99/9999" placeholder="DD/MM/YYYY" />
                </WrapperLabel>
                <WrapperLabel label="Mãe" xs={12} sm={6}>
                  <Input name="mather" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>
                <WrapperHidden show={showForm}>
                  <WrapperLabel label="RG" xs={12} sm={6}>
                    <Input name="document_secondary" type="tel" disabled={!!formData?.document_secondary} />
                  </WrapperLabel>
                  <WrapperLabel label="Pai" xs={12} sm={6}>
                    <Input name="father" style={{ textTransform: 'uppercase' }} disabled={!!formData?.father} />
                  </WrapperLabel>
                  <WrapperLabel label="Bnmp" xs={12} sm={6}>
                    <Input name="bnmp" style={{ textTransform: 'uppercase' }} disabled={!!formData?.bnmp} />
                  </WrapperLabel>
                </WrapperHidden>
              </>
            )}
            <FormControlLabel
              label={<Red go={showData}>Não tenho CPF.</Red>}
              control={(
                <Switch
                  onChange={handleChange}
                  color="primary"
                  name="checked"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
            />
          </Grid>
        </Div>

        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
              onClick={() => labelRef.current?.click()}
            >
              <InfoIcon />
            </ButtonIcon>

            <label ref={labelRef} htmlFor="modal-instruction" />
            <Modal id="modal-instruction" width={520} height="auto">
              <h1>Instruções</h1><br />
              <p>
                Para solicitar uma consulta de pessoa você precisa no mínimo
                informar os seguintes dados:
              </p><br />
              <p><b>CPF</b> ou</p>
              <p><b>Nome e Nascimento</b> ou</p>
              <p><b>Nome e Mãe</b>. </p>
              <br />
            </Modal>

          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="button"
              onClick={() => {
                if (showForm) {
                  formRef.current?.submitForm();
                } else {
                  handleGetPerson();
                }
              }}
              isLoading={btnLoading}
            >
              Consultar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>

      {persons.length > 0 ? (<RedStyle>Clique na pessoa consultada. </RedStyle>) : ''}
      {!!persons && persons?.map((person: any, index: number) => (
        <Card key={index} onClick={() => { handleGoToDetail(person); }}>
          <StyleCard person={person} />
        </Card>
      ))}

      <WrapperModal>
        <label ref={labelConfRef} htmlFor="modal-conf" />
        <Modal id="modal-conf" width={520} height="auto">
          <h2>
            <WarningIcon /> Confirma os dados da pessoa consultada ?
          </h2>
          {formData?.document && (
          <p>Cpf: <b>{formData?.document}</b></p>
          )}
          <b>{formData?.idpessoa}</b>
          {formData?.bnmp ? '' : ''}
          <p>Nome: <b>{formData?.name.toUpperCase()}</b></p>
          <p>Nascimento: <b>{formatDate(formData?.birthday)}</b></p>
          <p>Mãe: <b>{formData?.mather.toUpperCase()}</b></p>
          {formData?.death && (
            formData?.death !== '0' ? (
              <Death> Óbito: {formData?.death}</Death>
            ) : '')}
          {formData?.situation && (
            formData?.situation !== 'REGULAR' ? (
              <p>Situação cadastral: <b>{formData?.situation}</b></p>
            ) : '')}

          <FormControlLabel
            label={<Red>Confirmo as informações acima.</Red>}
            control={(
              <Switch
                onChange={handleChangeChecked}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          />

          <span>
            <Button
              onClick={handleHover}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => formRef.current?.submitForm()}
              isLoading={btnLoading}
              disableForce={!disableBtn}
            >
              Confirmar
            </Button>
          </span>

        </Modal>
      </WrapperModal>
      <ModalFactory />
    </Container>
  );
};
export default Data;
