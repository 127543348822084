import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import AsyncSelect from 'react-select/async';
import * as Yup from 'yup';
import typeRegistration from 'utils/typeRegistration';
import originOcorrence from 'utils/originOcorrence';
import typeWarrant from 'utils/typeWarrant';
import Layout from 'components/Layout';
import { AiOutlineFileText } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import Simple from 'components/Headers/Simple';
import { FileAltIcon } from 'styles/icons';
import { isAfter } from 'date-fns';
import { parseDate } from 'utils';
import getValidationError from '../../../../utils/getValidationsErros';
import {
  Container, GridButtons, WrapperBtnCancel,
  WrapperBtnSubmit, customStyle, LineTitle, WrapperAddress, Title, WrapperHidden, customStyleErr,
} from './styles';
import {
  WrapperLabel, Select, Button, Input, TextArea, InputMask,
} from '../../../../components/Forms';
import { useHttp } from '../../../../hooks/http';

interface IOptions {
  value: string;
  label: string
}

const OccurrenceForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [upaj, setUpaj] = useState<IOptions>();
  const [serviceTeam, setServiceTeam] = useState<IOptions[]>();
  const [sectorService, setSectorService] = useState<IOptions[]>();
  const [warrant, setWarrant] = useState<IOptions[]>();
  const history = useHistory();
  const { httpGet, httpPost } = useHttp();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [municipio, setMunicipio] = useState([]);
  const [notNumber, setnotNumber] = useState(false);
  const [selected, setSelected] = useState(false);
  const [RI, setRI] = useState(false);
  const [RCA, setRCA] = useState(false);
  const [errUpaj, setErrUpaj] = useState(false);
  const [errTeam, setErrTeam] = useState([]);

  const loadServiceTeans = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 5) return;
    const serviceTeam: any = [];
    const { status, data } = await httpGet(`/bopms/users/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        serviceTeam.push({
          value: `${item.document}`,
          label: `${item.function} ${item.war_name} - ${item.document}`,
        });
      });
    }

    callback(serviceTeam);
  };

  useEffect(() => {
    async function loadDitricts(): Promise<any> {
      const { status, data } = await httpGet('https://servicodados.ibge.gov.br/api/v1/localidades/estados/33/municipios');
      if (status === 200) {
        const municipioParsed = data
          .map((municipio: any) => ({
            value: municipio.nome,
            label: municipio.nome,
          }));
        setMunicipio(municipioParsed);
      }
    }
    loadDitricts();

    async function loadSectotService(): Promise<any> {
      const { status, data } = await httpGet(`/bopms/sectors/${user?.id}`);
      if (status === 200) {
        const sectorService = data
          .map((sector: any) => ({
            value: `${sector.initials}`,
            label: `${sector.initials}`,
          }));
        setSectorService(sectorService);
      }
    }

    loadSectotService();
  }, [httpGet, user]);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 2) return;

    const uapj: any = [];
    const { status, data } = await httpGet(`/upajs/?page=1&perPage=20&filter=${inputValue ?? ''}`);
    if (status === 200) {
      data.data.forEach((item: any) => {
        uapj.push({
          value: item.id,
          label: `${item.initials} ${item.name}`,
        });
      });
    }
    callback(uapj);
  };
  const [schoolEnvironment] = useState([
    { value: 'true', label: 'SIM' },
    { value: 'false', label: 'NÃO' },
  ]);

  const handleChangeAgent = useCallback((event: any) => {
    setUpaj(event);
  }, []);

  const handleChangeTypeWarrant = useCallback((event: any) => {
    setWarrant(event);
  }, []);

  useEffect(() => {
    const estaPresente = warrant?.some((item) => item.value === 'OUTROS');
    if (estaPresente === true) { setSelected(true); } else {
      setSelected(false);
                formRef.current?.setFieldValue('others', '');
    }
  }, [warrant]);

  const handleChangeServiceTeam = useCallback((event: any) => {
    setServiceTeam(event);
  }, []);

  const handleNotNumber = useCallback((event) => {
    const keyCode = (event.keyCode ? event.keyCode : event.which);
    if (keyCode > 47 && keyCode < 58) {
      event.preventDefault();
      setnotNumber(true);
    } else { setnotNumber(false); }
  }, []);

  const handleTypeRegistration = useCallback((event: any) => {
    const typeRegistration = event?.value;
    if (typeRegistration === 'RI') {
      formRef.current?.setFieldValue('type_of_warrant', [{ value: 'OUTROS', label: 'OUTROS' }]);
      setRI(true);
    } else {
      setRI(false);
      formRef.current?.setFieldValue('type_of_warrant', '');
      setUpaj(undefined);
    }
    if (typeRegistration === 'RCA') { setRCA(true); } else { setRCA(false); }
  }, []);

  const handleSubmit = useCallback(async (playload: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const _bopm = {
      ...playload,
      service_team: serviceTeam ? serviceTeam?.map((team) => team.value).join(',') : null,
      sector: playload.sector ? playload.sector?.map((sec:any) => sec.value).join(',') : null,
      type_of_warrant: playload.type_of_warrant ? playload.type_of_warrant?.map((type:any) => type.value).join(',') : null,
      management_id: user?.allocation?.management_id,
      addresses_id: playload.addresses_id,
      origin_of_occurrence: playload.origin_of_occurrence?.value || '',
      type_of_registration: playload.type_of_registration?.value || '',
      registration_number: RI === false ? playload.registration_number : '',
      body_cam: playload?.body_cam,
      upaj_id: RI === false ? upaj?.value : '',
      //
      started_time_at: `1900-01-01 ${playload.started_time_at} -0300`,
      started_date_at: `${parseDate(playload.started_date_at)} 00:00:00.000 -0300`,

      finish_time_at: RI === false ? `1900-01-01 ${playload?.finish_time_at}:00.000 -0300` : null,
      finish_date_at: RI === false ? `${parseDate(playload.finish_date_at)} 00:00:00.000 -0300` : null,
      //
      number: playload?.number || '',
      neighborhood: playload?.neighborhood || '',
      complement: playload?.complement || '',
      city: playload.city?.value || '',
      history: playload?.history || '',
      school_environment: playload?.school_environment?.value,
      warrants: selected,

    };
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        type_of_registration: Yup.string().required('Tipo de registro é obrigatório'),
        service_team: Yup.string().required('Equipe de serviço é obrigatório ter no mínimo 1(um)'),
        sector: Yup.string().typeError('Setor de serviço é obrigatório ter no mínimo 1(um)'),
        origin_of_occurrence: Yup.string().required('Origem da Ocorrência é obrigatório'),
        type_of_warrant: Yup.string().required('Tipo de Mandado é obrigatório'),
        others: Yup.string().when('warrants', {
          is: (val) => val === 'OUTROS',
          then: Yup.string().required('Descrição é obrigatório'),
          otherwise: Yup.string().notRequired(),
        }),
        registration_number: Yup.string().when('type_of_registration', {
          is: (val) => val !== 'RI',
          then: Yup.string().required('Número de registro é obrigatório'),
          otherwise: Yup.string().notRequired(),
        }),
        upaj_id: Yup.string().when('type_of_registration', {
          is: (val) => val !== 'RI',
          then: Yup.string().required('Número de serie obrigatório'),
          otherwise: Yup.string().notRequired().nullable(),
        }),
        started_date_at: Yup.date().typeError(RI === false ? 'Insira a data de início da ocorrência' : 'Insira a data do fato'),
        started_time_at: Yup.date().typeError(RI === false ? 'Insira a hora do início da ocorrência' : 'Insira a hora do fato'),

        finish_date_at: Yup.date().when('type_of_registration', {
          is: (val) => val === 'RI',
          then: Yup.date().notRequired().nullable(),
          otherwise: Yup.date().typeError('Insira a data término da ocorrência')
            .min(Yup.ref('started_date_at'), 'A data não pode ser anterior ao início da ocorrência'),
        }),
        finish_time_at: Yup.date().when('type_of_registration', {
          is: (val) => val === 'RI',
          then: Yup.date().notRequired().nullable(),
          otherwise: Yup.date().when('finish_date_at', {
            is: (val) => isAfter(val, new Date(_bopm.started_date_at)),
            then: Yup.date().typeError('Insira a término da ocorrência'),
            otherwise: Yup.date().typeError('Insira a término da ocorrência')
              .min(Yup.ref('started_time_at'), 'A Hora não pode ser anterior ao início da ocorrência'),
          }),
        }),
        school_environment: Yup.string().required('Esta opção é obrigatório'),
        street: Yup.string().required('Logradouro é obrigatório'),
        neighborhood: Yup.string().required('Bairro é obrigatório'),
        city: Yup.string().required('O Município é obrigatório'),
        history: Yup.string().required('Histórico é obrigatório'),
      });
      setErrTeam(_bopm?.service_team);
      setErrUpaj(_bopm?.upaj_id === undefined);

      await schema.validate(_bopm, { abortEarly: false });
      const { status } = await httpPost('bopms', _bopm);

      if (status === 200) {
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        history.push('/RO/step');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [serviceTeam, upaj, user, RI, addToast, history, httpPost, selected]);

  const breadcrumbs = {
    icon: FileAltIcon,
    links: [{ path: '/dashboard', title: 'RO-SP / Ocorrência / Cadastrar' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title>
            <AiOutlineFileText />Cadastrar {RI === false ? 'Ocorrência' : 'Registro Interno'}
          </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Equipe de serviço" xs={12} md={6} sm={6}>
                  <div style={{ width: '100%' }}>
                    <AsyncSelect
                      name="service_team"
                      placeholder="Digite o CPF"
                      noOptionsMessage={() => 'Sem registos'}
                      cacheOptions
                      isClearable
                      loadOptions={loadServiceTeans}
                      onChange={handleChangeServiceTeam}
                      value={serviceTeam}
                      styles={errTeam === undefined ? customStyleErr : customStyle}
                      isMulti
                    />
                  </div>
                </WrapperLabel>

                <WrapperLabel label="Setor de serviço" xs={12} md={6} sm={6}>
                  <div style={{ width: '100%' }}>
                    <Select
                      name="sector"
                     // onChange={handleChangeSectorService}
                      options={sectorService}
                      isMulti
                    />
                  </div>
                </WrapperLabel>

                <WrapperLabel label="Tipo de registro" xs={12} md={6} sm={6}>
                  <Select
                    name="type_of_registration"
                    options={typeRegistration}
                    onChange={handleTypeRegistration}
                  />
                </WrapperLabel>
                <WrapperLabel label="Origem da ocorrência" xs={12} md={6} sm={6}>
                  <Select
                    name="origin_of_occurrence"
                    options={originOcorrence}
                  />
                </WrapperLabel>

                <WrapperLabel label="Tipificação" xs={12} md={6} sm={6}>
                  <Select
                    name="type_of_warrant"
                    options={typeWarrant}
                    onChange={handleChangeTypeWarrant}
                    isMulti
                  />
                </WrapperLabel>

                <WrapperHidden show={selected} xs={12} md={6} sm={6}>
                  <WrapperLabel label="Descrição">
                    <Input name="others" style={{ textTransform: 'uppercase' }} />
                  </WrapperLabel>
                </WrapperHidden>

                {RI === false
                && (
                  <>
                    <WrapperLabel label="Número do registro" xs={12} md={3} sm={3}>
                      <InputMask name="registration_number" mask={RCA === false ? '999-99999/9999' : '999/9999'} style={{ textTransform: 'uppercase' }} />
                    </WrapperLabel>

                    <WrapperLabel label="Delegacia" xs={12} md={3} sm={3}>
                      <div style={{ width: '100%' }}>
                        <AsyncSelect
                          name=" upaj_id"
                          placeholder="Digite o número"
                          noOptionsMessage={() => 'Sem registos'}
                          cacheOptions
                          isClearable
                          loadOptions={loadOptions}
                          onChange={handleChangeAgent}
                          value={upaj}
                          styles={errUpaj === true ? customStyleErr : customStyle}
                        />
                      </div>
                    </WrapperLabel>
                  </>
                )}
                <LineTitle>
                  Dados da ocorrência:
                </LineTitle>

                <WrapperLabel label="Data início" xs={6} md={3} sm={3}>
                  <InputMask name="started_date_at" type="tel" mask="99/99/9999" />
                </WrapperLabel>

                <WrapperLabel label="Hora início" xs={6} md={3} sm={3}>
                  <InputMask name="started_time_at" type="tel" mask="99:99" />
                </WrapperLabel>

                {RI === false
                && (
                  <>
                    <WrapperLabel label="Data término" xs={6} md={3} sm={3}>
                      <InputMask name="finish_date_at" type="tel" mask="99/99/9999" />
                    </WrapperLabel>

                    <WrapperLabel label=" Hora término" xs={6} md={3} sm={3}>
                      <InputMask name="finish_time_at" type="tel" mask="99:99" />
                    </WrapperLabel>
                  </>
                )}

                <LineTitle>
                  Local da ocorrência:
                </LineTitle>

                <WrapperLabel label={notNumber === false ? 'Logradouro' : 'Somente Letras'} style={notNumber === false ? { color: '#131212' } : { color: '#c20a0a' }} xs={12} md={6} sm={6}>
                  <Input name="street" style={{ textTransform: 'uppercase' }} onKeyPress={handleNotNumber} />
                </WrapperLabel>

                <WrapperLabel label="Número" xs={6} sm={3} md={3}>
                  <Input name="number" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Complemento" xs={6} sm={3} md={3}>
                  <Input name="complement" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Município" xs={12} md={5} sm={5}>
                  <Select
                    name="city"
                    options={municipio}
                    style={{ textTransform: 'uppercase' }}
                  />
                </WrapperLabel>

                <WrapperLabel label="Bairro" xs={12} md={5} sm={5}>
                  <Input name="neighborhood" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Escolares" xs={12} sm={2} md={2}>
                  <Select name="school_environment" options={schoolEnvironment} />
                </WrapperLabel>

                <WrapperLabel label="Histórico" xs={12} sm={12} md={12}>
                  <TextArea row={5} name="history" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

              </Grid>
              <GridButtons>
                <WrapperBtnCancel>
                  <Button
                    type="button"
                    onClick={() => { history.push('/dashboard'); }}
                  >
                    Cancelar
                  </Button>
                </WrapperBtnCancel>
                <WrapperBtnSubmit>
                  <Button type="submit">
                    Salvar
                  </Button>
                </WrapperBtnSubmit>
              </GridButtons>
            </UForm>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default OccurrenceForm;
