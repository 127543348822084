import React, { useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import {
  DashIcon,
  PowerOffIcon,
  GearIcon,
  IosArrowBackIcon,
  IosArrowForwardIcon,
  PeopleIcon,
  BuildingIcon,
  ListIcon,
  ChartBarIcon,
  ExternalIcon,
  UsersIcon,
  HandsHelpingIcon,
  HandHoldingHeartIcon,
  PoliceCarFill,
  BuildingFillIcon,
  ViewListIcon,
  SupportIcon,
  ReadmeIcon,
  GoogleclassroomIcon,
  EventNoteIcon,
} from '../../../styles/icons';

import SubMenu from '../SubMenu';
import MenuItem from '../MenuItem';

import { Brand, MyIcon } from './styles';
import brandImg from '../../../assets/logo-bco.svg';
import horusImg from '../../../assets/horus-bco.svg';
import owlImage from '../../../assets/owl.svg';

interface IAside {
  collapsed: boolean;
  toggled: boolean;
  handleToggleSidebar(value: boolean): void;
  handleCollapsedChange(value: boolean): void;
}

const Aside: React.FC<IAside> = ({
  collapsed, toggled, handleToggleSidebar, handleCollapsedChange,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const handleSignOut = useCallback(() => {
    history.push('/signin');
    addToast({
      type: 'success',
      title: 'Logout realizado com sucesso.',
    });
    signOut();
  }, [history, signOut, addToast]);

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <Brand collapsed={collapsed}>
          <div>
            <img src={brandImg} alt="brand" />
            {!collapsed && (
              <span>
                <p>V {process.env.REACT_APP_VERSION}</p>
                <img src={horusImg} alt="brand" />
              </span>
            )}
          </div>
          <button type="button" onClick={() => handleCollapsedChange(true)}>
            {collapsed ? <IosArrowForwardIcon size={24} /> : <IosArrowBackIcon size={24} />}
          </button>
        </Brand>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="round">
          <MenuItem icon={<DashIcon />}>
            Dashboard
            <Link to="/dashboard" />
          </MenuItem>
          <SubMenu title="Controlar Presença" icon={<UsersIcon />} can="r-service">
            <SubMenu title="Serviços" can="r-service">
              <MenuItem>
                Listar
                <Link to="/services" />
              </MenuItem>
              <MenuItem can="c-service">
                Novo
                <Link to="/services/store" />
              </MenuItem>
              <MenuItem is="admin">
                CSV importar
                <Link to="/services/csv-upload" />
              </MenuItem>
            </SubMenu>

            <SubMenu title="Setores" can="r-sector">
              <MenuItem>
                Listar
                <Link to="/sectors" />
              </MenuItem>
              <MenuItem can="c-sector">
                Novo
                <Link to="/sector" />
              </MenuItem>
            </SubMenu>

            <SubMenu title="Apuração" can="c-poll">
              <MenuItem>
                Listar
                <Link to="/polls" />
              </MenuItem>
              <MenuItem can="c-poll">
                Novo
                <Link to="/poll" />
              </MenuItem>
            </SubMenu>
          </SubMenu>

          <SubMenu title="Consultas" icon={<ListIcon />} can="r-orders">
            {!!user?.allocation && (
              <>
                <MenuItem>
                  Listar
                  <Link to="/orders/me" />
                </MenuItem>
                <MenuItem can="c-orders">
                  Pessoa
                  <Link to="/order/person" />
                </MenuItem>
                <MenuItem can="c-orders">
                  Veículo
                  <Link to="/order/vehicle" />
                </MenuItem>
                <MenuItem can="c-orders">
                  BodyCam
                  <Link to="/bodycam" />
                </MenuItem>
                <MenuItem can="c-pmerj">
                  Pmerj
                  <Link to="/pmerj" />
                </MenuItem>
              </>
            )}
          </SubMenu>
          <SubMenu title="RO-SP" icon={<PoliceCarFill />} can="c-rosp">
            <MenuItem can="r-rosp">
              Listar
              <Link to="/RO/list" />
            </MenuItem>
            <MenuItem can="r-RO">
              Listar - Fiscal
              <Link to="/RO/list-fisc" />
            </MenuItem>
            <MenuItem can="c-rosp">
              Novo
              <Link to="/RO" />
            </MenuItem>
            <MenuItem can="d-rosp">
              Dashboard
              <Link to="/RO/dashboard" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Suporte" icon={<SupportIcon />} is="support">
            <MenuItem can="r-support">
              Listar
              <Link to="/support" />
            </MenuItem>
            <MenuItem can="c-support">
              Novo
              <Link to="/support/create" />
            </MenuItem>
            <MenuItem can="c-support">
              L8 Api
              <Link to="/support/observer" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="L8" icon={<SupportIcon />} is="l8">
            <MenuItem can="c-technician">
              Dashboard
              <Link to="/support/awaiting" />
            </MenuItem>
            <MenuItem can="c-technician">
              Listar
              <Link to="/support/awaiting/list" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="L8" icon={<SupportIcon />} is="technician">
            <MenuItem can="r-technician">
              L8-Técnico
              <Link to="/support/technician" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Lei Seca" icon={<ViewListIcon />} is="leiseca">
            <MenuItem can="r-leiseca">
              Dashboard - Fiscal
              <Link to="/leiseca/dashboard" />
            </MenuItem>
            <MenuItem can="r-leiseca">
              Listar - Fiscal
              <Link to="/leiseca" />
            </MenuItem>
            <MenuItem can="r-leisecaedu">
              Dashboard - Educação
              <Link to="/leiseca/dashboard-educacao" />
            </MenuItem>
            <MenuItem can="r-leisecaedu">
              Listar - Educação
              <Link to="/leiseca-educacao" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Rcols" icon={<ViewListIcon />} can="c-rcols">
            {!!user?.allocation && (
              <>
                <MenuItem can="c-rcols">
                  Listar
                  <Link to="/rcols" />
                </MenuItem>
                <MenuItem can="c-rcols">
                  Novo
                  <Link to="/rcol" />
                </MenuItem>
              </>
            )}
          </SubMenu>
          <SubMenu title="Reducols" icon={<ViewListIcon />} can="c-reducols">
            {!!user?.allocation && (
              <>
                <MenuItem can="c-reducols">
                  Listar
                  <Link to="/reducols" />
                </MenuItem>
                <MenuItem can="c-reducols">
                  Novo
                  <Link to="/reducol" />
                </MenuItem>
              </>
            )}
          </SubMenu>
          <SubMenu title="Mrcols" icon={<ViewListIcon />} can="c-mrcols">
            {!!user?.allocation && (
              <>
                <MenuItem can="c-mrcols">
                  Novo
                  <Link to="/mrcols" />
                </MenuItem>
              </>
            )}
          </SubMenu>
          <SubMenu title="BDTD" icon={<PoliceCarFill />} can="c-bdtd">
            {!!user?.allocation && (
              <>
                <MenuItem can="c-bdtd">
                  Listar
                  <Link to="/bdtds" />
                </MenuItem>
                <MenuItem can="c-bdtd">
                  Novo
                  <Link to="/bdtd" />
                </MenuItem>
                <MenuItem can="s-bdtd">
                  Fiscal
                  <Link to="/sbdtds/supervisor" />
                </MenuItem>
              </>
            )}
          </SubMenu>
          <SubMenu title="Formulário de Serviço" icon={<EventNoteIcon />} can="c-company">
            <MenuItem can="s-bdtd">
              Manutenção
              <Link to="/maintenances" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Transporte" icon={<BuildingFillIcon />} is="transport">
            <SubMenu title="Manutenção">
              <MenuItem can="c-company">
                Listar
                <Link to="/maintenance/list" />
              </MenuItem>
              <MenuItem can="c-company">
                Dashboard
                <Link to="/companys" />
              </MenuItem>
              <MenuItem can="c-company">
                Histórico
                <Link to="/company/history" />
              </MenuItem>
            </SubMenu>
            <MenuItem can="s-bdtd">
              Bdtds
              <Link to="/sbdtds/supervisor" />
            </MenuItem>
            <SubMenu title="Viatura">
              <MenuItem can="c-fleets">
                Listar
                <Link to="/fleets" />
              </MenuItem>
              <MenuItem can="c-fleets">
                Novo
                <Link to="/fleet" />
              </MenuItem>
            </SubMenu>
            <SubMenu title="Posto de Combustível">
              <MenuItem can="c-gases">
                Listar
                <Link to="/gases" />
              </MenuItem>
              <MenuItem can="c-gases">
                Novo
                <Link to="/gas" />
              </MenuItem>
            </SubMenu>
            <SubMenu title="Marcas e Modelos">
              <MenuItem can="c-fleets">
                Listar
                <Link to="/makes" />
              </MenuItem>
              <MenuItem can="c-fleets">
                Nova Marca
                <Link to="/make" />
              </MenuItem>
              <MenuItem can="c-fleets">
                Novo Modelo
                <Link to="/model" />
              </MenuItem>
            </SubMenu>
          </SubMenu>

          <SubMenu title="Proximidade" icon={<HandsHelpingIcon />} can="r-proximity">
            {!!user?.allocation && (
              <>
                <MenuItem>
                  Listar
                  <Link to="/orders/proximity/list-user" />
                </MenuItem>
                <MenuItem can="r-proximity">
                  Novo
                  <Link to="/order/proximity" />
                </MenuItem>
                <MenuItem is="coord">
                  Base
                  <Link to="/orders/proximity/list-base" />
                </MenuItem>
              </>
            )}
          </SubMenu>
          <SubMenu title="Social" can="r-social" icon={<HandHoldingHeartIcon />}>
            <MenuItem can="r-social">
              Listar <Link to="/socials/list-user" />
            </MenuItem>
            <MenuItem can="c-social">
              Novo <Link to="/social" />
            </MenuItem>
            <MenuItem is="associal">
              Equipe <Link to="/socials/list-base" />
            </MenuItem>
          </SubMenu>

          <>
            {user?.is_intelligence && (
              <SubMenu title="Nuint" icon={<MyIcon src={owlImage} />}>
                <MenuItem>
                  Listar
                  <Link to="/orders/answered" />
                </MenuItem>
                <MenuItem>
                  Fechar
                  <Link to="/orders/awaiting" />
                </MenuItem>

                <SubMenu title="Cadastros" icon={<ChartBarIcon />} can="r-people">
                  <MenuItem>
                    Pessoas
                    <Link to="/people" />
                  </MenuItem>
                  <MenuItem>
                    Veículos
                    <Link to="/vehicles" />
                  </MenuItem>
                </SubMenu>
              </SubMenu>
            )}
          </>

          <MenuItem icon={<ChartBarIcon />} is="statistic">
            Estatística
            <Link to="/statistic" />
          </MenuItem>

          <SubMenu title="Registros" icon={<ExternalIcon />} can="r-register">
            <MenuItem>
              Formulário de encerramento
              <Link to="/closing-forms" />
            </MenuItem>
            <MenuItem>
              <a
                href="https://sites.google.com/view/operaoseguranapresente/ocorr%C3%AAncias?authuser=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ocorrências
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="https://sites.google.com/view/operaoseguranapresente/livro-de-parte-di%C3%A1ria?authuser=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                LPD
              </a>
            </MenuItem>
          </SubMenu>

          <SubMenu title="Gerências" icon={<BuildingIcon />} is="base">
            <MenuItem>
              Listar
              <Link to="/managements" />
            </MenuItem>
            <MenuItem is="admin">
              Nova
              <Link to="/management" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Capacitação" icon={<GoogleclassroomIcon />}>
            <MenuItem can="r-orders">
              Modulo <Link to="/classrooms" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Tutorial" icon={<ReadmeIcon />} can="r-orders">
            <MenuItem>
              Pops <Link to="/pops" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Usuários" icon={<PeopleIcon />} can="r-user">
            <MenuItem can="r-user">
              Listar <Link to="/users" />
            </MenuItem>
            <MenuItem can="c-user">
              Novo <Link to="/user" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Sistema" icon={<GearIcon />} is="admin">
            <MenuItem is="admin">
              Acl <Link to="/acl" />
            </MenuItem>
            <MenuItem is="admin">
              Settings <Link to="/settings/default" />
            </MenuItem>
            <MenuItem is="admin">
              Cproeis Name <Link to="/settings/cproeis-name" />
            </MenuItem>
          </SubMenu>

        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div className="sidebar-btn-wrapper">
          <button
            type="button"
            className="sidebar-btn"
            onClick={handleSignOut}
          >
            <PowerOffIcon />
            <span>Sair</span>
          </button>
        </div>
      </SidebarFooter>

    </ProSidebar>
  );
};

export default Aside;
